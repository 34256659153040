import axios from "axios";
import { arrayToObject } from "@/services/helpers";

// get the store instance
import store from "../store/index";

axios.defaults.baseURL = `/b-api/`;
// axios.defaults.headers.common['X-XSRF-TOKEN'] = token; // this is automatically done by axios.
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Add a response interceptor.

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // not logged in

        // store.dispatch('ui/setSnackbar', {
        //   variant: 'info',
        //   title: 'Not Logged In',
        //   message: 'Redirecting to Login Page',
        // });
        store.commit("auth/logout", "", { root: true });
        store.dispatch("auth/specificCompanyDataReset", "", { root: true });
        store.commit("companies/reset", "", { root: true });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);

        return new Promise(() => {}); // this essentially cancels whatever the response would have been, so no errors shown
      }

      if (error.response.status === 444) {
        store.dispatch("ui/setSnackbar", {
          variant: "info",
          title: "Broswer out of sync with server.",
          message: "You may have opened a second tab or window. Refreshing the page.",
        });
        // store.dispatch('auth/specificCompanyDataReset', '', { root: true });
        // store.commit('companies/reset', '', { root: true });
        setTimeout(() => {
          window.location.reload(true);
        }, 3000);

        return new Promise(() => {}); // this essentially cancels whatever the response would have been, so no errors shown
      }

      if (error.response.status === 419) {
        // CSRF ISSUE
        store.dispatch("ui/setSnackbar", {
          variant: "info",
          title: "App Error",
          message: "Page must reload, your previous action was likely not completed",
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 4000);
      }
    }

    return Promise.reject(error);
  },
);

function setCompanyHeader() {
  delete axios.defaults.headers.common["CURRENT-COMPANY-ID"];
  if (store && store.state.auth && store.state.auth.user && store.state.auth.user.current_company_id) {
    axios.defaults.headers.common["CURRENT-COMPANY-ID"] = store.state.auth.user.current_company_id;
  }
}

export function get(path) {
  setCompanyHeader();
  return axios({
    method: "get",
    url: path,
  });
}

/*
The returned promise should act like this
api.downloadFile(`/providers/psa/pdf`).then((response) => {
  var file = new Blob([response.data], {type: 'application/pdf'});
  var fileURL = URL.createObjectURL(file);

  //To Download the file
  const link = document.createElement('a');
  link.href = fileURL;
  link.setAttribute('download', 'file.pdf');
  document.body.appendChild(link);
  link.click();

  //To Open in new window
  window.open(fileURL);
*/
export function downloadFile(path, data) {
  return axios({
    method: "post",
    url: path,
    responseType: "blob", // important
    data,
  });
}

export function post(path, data) {
  setCompanyHeader();
  return axios({
    method: "post",
    url: path,
    data,
  });
}

export function patch(path, data) {
  setCompanyHeader();
  return axios({
    method: "patch",
    url: path,
    data,
  });
}

export function destroy(path) {
  setCompanyHeader();
  return axios({
    method: "delete",
    url: path,
  });
}

export async function fetchBuyers() {
  setCompanyHeader();
  const response = await axios.get("/buyers");
  return arrayToObject(response.data, "id");
}
