<template>
  <div ref="dropdown" class="dropdown">
    <slot name="trigger" :toggle="toggleDropdown">
      <BushelBtn
        ref="triggerBtn"
        :style="btnStyle"
        :class="[size === 'sm' ? 'btn-sm' : '', size === 'lg' ? 'btn-lg' : '', btnClass]"
        :outline="outline"
        :variant="variant"
        :flat="flat"
        :ghosted="ghosted"
        :button-id="buttonId"
        :tooltip-message="tooltipMessage"
        @click="(event) => toggleDropdown(event)"
      >
        <span class="mr-1">{{ label }}</span>
        <i class="fa fa-caret-down" />
      </BushelBtn>
    </slot>

    <Teleport v-if="isOpen" to="body">
      <transition name="slide-menu" appear>
        <div v-if="isOpen" ref="dropdownMenu" :class="{ 'dropdown-menu': true }" role="menu">
          <div ref="arrow" class="arrow"></div>
          <div
            :class="{
              'dropdown-menu-inner': true,
              [dropdownMenuClass]: dropdownMenuClass,
              'h-100': true,
              'w-100': true,
              'bg-white': true,
              'position-relative': true,
            }"
            style="z-index: 2000"
          >
            <slot :toggle="toggleDropdown" />
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { computePosition, flip, shift, offset, autoUpdate, arrow } from "@floating-ui/dom";
import BushelBtn from "@/components/common/BushelBtn.vue";

export default {
  name: "BDropdown",
  components: { BushelBtn },

  props: {
    variant: { type: String, default: "secondary" },
    size: { type: String, default: "md" },
    outline: { type: Boolean, default: false },
    label: { type: String, default: "Dropdown" },
    placement: { type: String, default: "bottom" },
    alignment: { type: String, default: "center" },
    dropdownMenuClass: { type: String, default: "" },
    btnStyle: { type: [Object, String] },
    btnClass: { type: [String, Object, Array], default: "" },
    ghosted: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    buttonId: { type: String, default: "" },
    tooltipMessage: { type: String, default: "" },
    offset: { type: Number, default: 26 },
  },

  data() {
    return {
      isOpen: false,
      cleanup: null,
    };
  },

  beforeUnmount() {
    this.cleanup?.();
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("resize", this.updateDropdownPosition);
    window.removeEventListener("scroll", this.updateDropdownPosition, true);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$nextTick(this.setupPosition);
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
        this.cleanup?.();
      }
    },

    setupPosition() {
      const reference = this.$refs.dropdown;
      const floating = this.$refs.dropdownMenu;
      const arrowElement = this.$refs.arrow;
      if (!reference || !floating) return;

      this.cleanup = autoUpdate(reference, floating, () => {
        const alignmentMap = {
          left: "start",
          right: "end",
          center: "center",
        };

        const placement = `${this.placement}-${alignmentMap[this.alignment] || "center"}`;

        computePosition(reference, floating, {
          strategy: "fixed",
          placement,
          middleware: [offset(this.offset), flip({ padding: 8 }), shift({ padding: 8 }), arrow({ element: arrowElement })],
        }).then(({ x, y, placement: actualPlacement, middlewareData }) => {
          Object.assign(floating.style, {
            position: "fixed",
            left: `${x}px`,
            top: `${y}px`,
            zIndex: 2000,
          });

          const { x: arrowX, y: arrowY } = middlewareData.arrow;

          const staticSide = {
            top: "bottom",
            bottom: "top",
            left: "right",
            right: "left",
          }[actualPlacement.split("-")[0]];

          Object.assign(arrowElement.style, {
            left: arrowX != null ? `${arrowX}px` : "",
            top: arrowY != null ? `${arrowY}px` : "",
            [staticSide]: "-10px",
            position: "absolute",
            zIndex: 1999,
          });
        });
      });
    },

    handleOutsideClick(event) {
      // Don't close if clicking inside the dropdown or its submenus
      if (this.$refs.dropdown?.contains(event.target) || event.target.closest(".dropdown-submenu")) {
        return;
      }

      const isOutside = !this.$refs.dropdownMenu?.contains(event.target);
      if (isOutside) {
        this.isOpen = false;
      }

      if (event.target.classList.contains("dropdown-item") || event.target.parentElement.classList.contains("dropdown-item")) {
        this.isOpen = false;
      }
    },
    // Remove or comment out updateDropdownPosition method as it's no longer needed
    // updateDropdownPosition() { ... }
  },
};
</script>

<style scoped>
.dropdown-menu {
  position: fixed;
  margin: 0;
  padding: 0;
  min-width: 150px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform-origin: top;
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
  z-index: 2080;
}

.dropdown-menu-inner {
  position: relative;
  border-radius: 4px;
  padding: 1rem;
}

/* Replace the .dropdown-menu::before with .arrow */
.arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
  z-index: -1;
}

.slide-menu-enter-active,
.slide-menu-leave-active {
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
}

.slide-menu-enter-from,
.slide-menu-leave-to {
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
}

.slide-menu-enter-to,
.slide-menu-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.dropdown-menu.has-submenu {
  overflow: visible !important;
  position: fixed;
  transform-origin: top center;
}
</style>
