<template>
  <textarea
    :id="id"
    :class="textareaClasses"
    :name="name"
    :rows="rows"
    :cols="cols"
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    :required="required"
    :readonly="readonly"
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :form="form"
    :maxRows="maxRows"
    :minRows="rows"
    @input="(event) => $emit('input', event)"
  />
</template>
<script>
export default {
  name: "BFormTextarea",
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rows: {
      type: [Number, String],
      default: 2,
    },
    cols: {
      type: [Number, String],
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    form: {
      type: String,
      default: null,
    },
    plaintext: {
      type: Boolean,
      default: false,
    },
    maxRows: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ["input"],
  computed: {
    textareaClasses() {
      return {
        "form-control": !this.plaintext,
        "form-control-plaintext": this.plaintext,
        "is-invalid": this.state === false,
        "is-valid": this.state === true,
      };
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>
