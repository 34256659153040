import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const destroyTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/shipping-orders/tasks/${payload.order_id}/${payload.id}`, payload);
    if (response.data.id) {
      commit("updateTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const notifyTaskUsers = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/tasks/notify-task-users/${payload.orderId}/${payload.taskId}`, payload);
    if (response.data.id) {
      commit("updateTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const updateTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/tasks/${payload.order_id}/${payload.id}`, payload);
    if (response.data.id) {
      commit("updateTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const createTaskLog = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/tasks/logs/${payload.order_id}/${payload.task_id}`, payload);
    if (response.data.id) {
      commit("updateTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const createTask = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/tasks/${payload.order_id}`, payload);
    if (response.data.id) {
      commit("addTask", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const updateOrderStatus = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/status/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const updateInvoiceSignature = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch("/shipping-orders/invoice-signature", payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const updateOrderPricingTier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/change-pricing-tier/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const updateOrderDealFlow = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/deal-flow/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const update = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const setSellerCancelled = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/set-seller-cancelled/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const deleteBuyerNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/shipping-orders/buyerNote/${payload}`);
    if (response.data === "success") {
      commit("deleteBuyerNote", payload);
      return "success";
    }
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "an error occurred while deleting the buyer note",
      },
      { root: true },
    );
    return "failure";
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "oh no! an error occurred while trying to delete a buyer note",
      },
      { root: true },
    );
    return "failure";
  }
};

export const fetchOrders = async ({ commit }) => {
  try {
    commit("ordersLoading");
    const response = await api.get("/shipping-orders");
    const orders = arrayToObject(response.data, "id");
    commit("ordersLoaded", orders);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Order List" }, { root: true });
  }
};

export const destroyPayment = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/shipping-orders/payments/${payload.order_id}/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const deletePricingTierSurcharge = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/delete-surcharge/${payload.order_id}/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const updatePayment = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/payments/${payload.order_id}/${payload.id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const createPayment = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/payments/${payload.order_id}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      commit("ui/setSnackbar", { variant: "success", message: "Success" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
  }
};

export const createOrderDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/documents/${payload.order_id}`, payload);
    if (response.data && response.data.id) {
      commit("replaceOrder", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Document" }, { root: true });
  }
};

export const deleteOrderDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/shipping-orders/documents/${payload.uuid}`);
    if (response.data && response.data.id) {
      commit("replaceOrder", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const notify = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/notify/${payload.order_id}`, payload);
    if (response.data.code == 200) {
      // commit('ui/setSnackbar', { variant: 'success', message: 'Notification Message Sent Successfully' }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Notification" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Sending Notification" }, { root: true });
  }
};

export const create = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/shipping-orders", payload);
    if (response.data.id) {
      commit("created", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};

export const createNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/shipping-orders/notes/${payload.order_id}`, payload);
    if (response.data.id) {
      commit("createdNote", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Note" }, { root: true });
  }
};

export const updateNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/notes/${payload.id}`, payload);
    if (response.data.id) {
      commit("updatedNote", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Note" }, { root: true });
  }
};

export const deleteNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/shipping-orders/notes/${payload.id}`);
    if (response.data && response.data.message == "success") {
      commit("deletedNote", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Note" }, { root: true });
  }
};

export const fetchOrder = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get(`/shipping-orders/${uuid}`);
    if (response.data.id) {
      commit("orderLoaded", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Order Data" }, { root: true });
    return "failure";
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: error.response.data.message }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Order Data" }, { root: true });
    }
    return "failure";
  }
};

export const addItem = async ({ commit, dispatch }, payload) => {
  try {
    commit("setItemsLoading", payload.cartItems);
    const response = await api.patch(`/shipping-orders/add-item/${payload.orderId}`, payload);

    if (response.data && response.data.id) {
      commit("replaceOrder", response.data);
      return { status: "Success" };
    }

    if (response.data && response.data.order && response.data.order.id) {
      commit("replaceOrder", response.data.order);
      return { status: "Success" };
    }

    /* for when we handle indiviual items
    if (response.data && response.data.length > 0) {
      commit('addItems', response.data);
      return { status: 'Success' };
    }

    */
    return response.data;
  } catch (error) {
    return { status: "Failure" };
  }
};

export const updateProductsAvailableOnOrder = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/update-products-available-on-order/${payload.orderId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const updateLineItemPrice = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/update-price/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Price" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Price" }, { root: true });
    return "failure";
  }
};

export const updateSampleQuantityLabel = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/modify-line-item-sample-label/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const updateLineItemNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/modify-line-item-note/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const deleteModifier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(
      `/shipping-orders/delete-modifier-from-invoice/${payload.orderId}/${payload.order_item_id}/${payload.id}`,
      payload,
    );
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    return "failure";
  } catch (error) {
    return "failure";
  }
};

export const splitLine = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/split-line/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "There was an error splitting this item" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "There was an error splitting this item" }, { root: true });
    return "failure";
  }
};

export const addModifier = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/add-modifier-to-invoice/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Item" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Item" }, { root: true });
    return "failure";
  }
};

export const fulfillPreorder = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/fulfill-preorder/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const modifyQuantity = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/modify-quantity/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const reenableBulkDiscountsOnLine = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/re-enable-bulk-discounts/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const removeMetrcPackage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/remove-metrc-package/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const attatchMetrcPackage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/add-metrc-package/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return "failure";
  }
};

export const createMetrcPackage = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/create-metrc-package/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.statusCode == 200) {
      commit("replaceOrder", response.data.order);
      return response.data;
    }
    if (response.data.statusCode) {
      return response.data;
    }
    return {
      statusCode: 500,
      errorMessage:
        "There was an unknown error creating this package. Try the re-sync package button on the orders page. If you still experience trouble please contact support.",
    };
  } catch (error) {
    return {
      statusCode: 500,
      errorMessage:
        "There was an unknown error creating this package. Try the re-sync package button on the orders page. If you still experience trouble please contact support.",
    };
  }
};

export const deleteItem = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/shipping-orders/delete-item/${payload.orderId}/${payload.orderItemId}`, payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return "success";
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Item" }, { root: true });
    return "failure";
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Item" }, { root: true });
    return "failure";
  }
};

export const reset = async ({ commit, dispatch }) => {
  try {
    commit("reset");
  } catch (error) {
    //
  }
};

export const splitOrder = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/shipping-orders/split-order", payload);
    if (response.data && response.data.code && response.data.code == 200) {
      if (response && response.data && response.data.data) {
        commit("replaceOrder", response.data.data.originalOrder);
        if (response.data.data.addToNewOrder) {
          commit("created", response.data.data.orderSplittingInto);
        } else {
          commit("replaceOrder", response.data.data.orderSplittingInto);
        }
      }
      commit("ui/setSnackbar", { variant: "success", message: "Orders Split Successfully" }, { root: true });
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Splitting Order" }, { root: true });
    }
    return response;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Splitting Order" }, { root: true });
    return error;
  }
};

export const updateDates = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post("/shipping-orders/update-dates", payload);
    if (response.data && response.status === 200) {
      commit("replaceOrder", response.data);
      commit(
        "ui/setSnackbar",
        {
          variant: "success",
          message: "Date Updated Successfully",
        },
        { root: true },
      );
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "Error Updating Dates",
        },
        { root: true },
      );
    }
    return response;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Updating Dates",
      },
      { root: true },
    );
    return error;
  }
};

export const updateOrderTransferId = async ({ commit, dispatch }, payload) => {
  try {
    commit("updateOrderTransferId", payload);
  } catch (error) {
    //
  }
};

export const updateOrderPicklistStatus = async ({ commit, dispatch }, payload) => {
  try {
    commit("replaceOrder", payload);
  } catch (error) {
    //
  }
};

export const updateSageOrderMapped = async ({ commit, dispatch }, payload) => {
  try {
    commit("replaceOrder", payload);
  } catch (error) {
    //
  }
};

export const clearItemsLoading = async ({ commit, dispatch }) => {
  try {
    commit("clearItemsLoading");
  } catch (error) {
    //
  }
};

export const fetchParentOrderStatuses = async ({ commit, dispatch }) => {
  try {
    const response = await api.get("/shipping-orders/parent-order-statuses");
    if (response.data && response.data.code && response.data.code == 200) {
      commit("setParentOrderStatuses", response.data.data);
    }
    return response;
  } catch (error) {
    commit(
      "ui/setSnackbar",
      {
        variant: "danger",
        title: "Error",
        message: "Error Loading Parent Order Statuses",
      },
      { root: true },
    );
    return error;
  }
};
