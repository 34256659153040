import * as api from "@/services/api";
import { arrayToObject } from "@/services/helpers";

export const fetchOrders = async ({ commit }) => {
  try {
    commit("ordersLoading");
    const response = await api.get("/receiving-orders");
    const orders = arrayToObject(response.data, "id");
    commit("ordersLoaded", orders);
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Fetching Order List" }, { root: true });
  }
};

export const fetchOrder = async ({ commit, dispatch }, uuid) => {
  try {
    const response = await api.get(`/receiving-orders/${uuid}`);
    if (response.data && response.data.id) {
      commit("orderLoaded", response.data);
    } else if (response.data && response.data.message && response.data.message == "order not claimed yet by buyer") {
      return response.data;
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "There was an error fetching this order. Please make sure you have the correct link. Reach out to Support if you need assistance",
        },
        { root: true },
      );
    }
    return "lint made me do it";
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: error.response.data.message }, { root: true });
    } else {
      commit(
        "ui/setSnackbar",
        {
          variant: "danger",
          title: "Error",
          message: "There was an error fetching this order. Please make sure you have the correct link. Reach out to Support if you need assistance",
        },
        { root: true },
      );
    }
    return error;
  }
};

export const reset = async ({ commit, dispatch }) => {
  try {
    commit("reset");
  } catch (error) {
    //
  }
};

export const createOrderDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/receiving-orders/documents/${payload.order_id}`, payload);
    if (response.data && response.data.id) {
      commit("orderDocCreated", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Document" }, { root: true });
  }
};

export const deleteOrderDoc = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/receiving-orders/documents/${payload.uuid}`);
    if (response.data == 1) {
      commit("orderDocDeleted", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Document" }, { root: true });
  }
};

export const createNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.post(`/receiving-orders/notes/${payload.order_id}`, payload);
    if (response.data.id) {
      commit("createdNote", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Creating Note" }, { root: true });
  }
};

export const updateNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch(`/receiving-orders/notes/${payload.id}`, payload);
    if (response.data.id) {
      commit("updatedNote", response.data);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Updating Note" }, { root: true });
  }
};

export const deleteNote = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.destroy(`/receiving-orders/notes/${payload.id}`);
    if (response.data && response.data.message == "success") {
      commit("deletedNote", payload);
    } else {
      commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Note" }, { root: true });
    }
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Error Deleting Note" }, { root: true });
  }
};

export const updateInvoiceSignature = async ({ commit, dispatch }, payload) => {
  try {
    const response = await api.patch("/receiving-orders/invoice-signature", payload);
    if (response.data.id) {
      commit("replaceOrder", response.data);
      return response.data;
    }
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return response.data;
  } catch (error) {
    commit("ui/setSnackbar", { variant: "danger", title: "Error", message: "Problem Updating Order" }, { root: true });
    return error;
  }
};
