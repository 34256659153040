import { deleteProperty } from "@/services/helpers";

export const created = (state, payload) => {
  state.list = { ...state.list, [payload.id]: payload };
};

export const replaceOrder = (state, payload) => {
  payload.items.forEach((item) => {
    if (state.itemsLoading.includes(item.batch_id)) {
      state.itemsLoading = state.itemsLoading.filter((loadingItem) => loadingItem !== item.batch_id);
    }
  });

  state.list[payload.id] = { ...state.list[payload.id], ...payload };
};

export const setItemsLoading = (state, payload) => {
  payload.forEach((item) => {
    state.itemsLoading.push(item.batch_id);
  });
};

export const addItems = (state, payload) => {
  payload.forEach((item) => {
    if (state.itemsLoading.includes(item.batch_id)) {
      state.itemsLoading = state.itemsLoading.filter((loadingItem) => loadingItem !== item.batch_id);
    }
  });

  state.list[payload[0].order_id].items = payload;
};

export const clearItemsLoading = (state, payload) => {
  state.itemsLoading = [];
};

export const removeOrder = (state, payload) => {
  state.list = deleteProperty(state.list, payload.toString());
};

export const addTask = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.order_id].task_checklist.push(payload) };
};

export const updateTask = (state, payload) => {
  const updatedTasks = state.list[payload.order_id].task_checklist.map((task) => {
    if (task.id == payload.id) {
      return payload;
    }
    return task;
  });
  // updatedTasks.push(payload);
  const updatedOrder = { ...state.list[payload.order_id], task_checklist: updatedTasks };
  state.list = { ...state.list, [payload.order_id]: updatedOrder };
};

export const orderLoaded = (state, payload) => {
  if (!state.list[payload.id]) {
    created(state, payload);
  } else {
    replaceOrder(state, payload);
  }
};

export const ordersLoaded = (state, payload) => {
  state.list = payload;
  state.ordersFetched = true;
  state.ordersLoading = false;
};

export const ordersLoading = (state) => {
  state.ordersFetched = false;
  state.ordersLoading = true;
};

export const reset = (state, payload) => {
  state.list = {};
  state.ordersFetched = false;
  state.ordersLoading = false;
};

// export const orderDocCreated = (state, payload) => {
//   state.list = { ...state.list, ...state.list[payload.docable_id].documents.push(payload) };
// };
//
// export const orderDocDeleted = (state, payload) => {
//   const foundOrder = state.list[payload.docable_id];
//   const updatedDocuments = foundOrder.documents.filter((doc) => (doc.id != payload.id));
//   state.list[payload.docable_id] = { ...foundOrder, documents: updatedDocuments };
// };

export const createdNote = (state, payload) => {
  state.list = { ...state.list, ...state.list[payload.order_id].notes.push(payload) };
};

export const deletedNote = (state, payload) => {
  const updatedNotes = state.list[payload.order_id].notes.filter((note) => note.id != payload.id);
  state.list[payload.order_id].notes = updatedNotes;
};

export const deleteBuyerNote = (state, payload) => {
  state.list[payload].buyer_note = undefined;
};

export const updatedNote = (state, payload) => {
  const updatedNotes = state.list[payload.order_id].notes.filter((note) => note.id != payload.id);
  updatedNotes.push(payload);
  const updatedOrder = { ...state.list[payload.order_id], notes: updatedNotes };
  state.list = { ...state.list, [payload.order_id]: updatedOrder };
};

export const updateOrderQbData = (state, payload) => {
  const order = state.list[payload.orderId];
  let currentItems = [];
  order.buyer.quickbooks_customer_id = payload.quickBooksCustomerId;
  if (payload.products && payload.products.length > 0) {
    payload.products.forEach((product, indexCurrentProduct) => {
      currentItems = [];
      currentItems = order.items.filter((orderItem) => orderItem.product_id == product.id);
      if (currentItems && currentItems.length > 0) {
        currentItems.forEach((currentItem, indexCurrentItem) => {
          currentItem.original_product.quickbooks_product_id = product.quickbooks_product_id;
        });
      }
    });
  }
};

export const removeOrderQbInvoice = (state, payload) => {
  const order = state.list[payload.quickbooksInvoice.order_id];
  const results = order.quickbooks_invoices.filter((invoice) => invoice.id != payload.quickbooksInvoice.id);
  order.quickbooks_invoices = results;
};

export const addOrderQbInvoice = (state, payload) => {
  const order = state.list[payload.quickbooksInvoice.order_id];
  order.quickbooks_invoices.push(payload.quickbooksInvoice);
};

export const updateOrderTransferId = (state, payload) => {
  const order = state.list[payload.orderId];
  order.metrc_transfer_template_id = payload.metrcTransferTemplateId;
};
