<template>
  <button
    :id="buttonId ? buttonId : uniqueId"
    ref="container"
    :disabled="disabled || loading"
    class="btn ripple-outer"
    :class="[
      btnType,
      btnContainerClass,
      {
        btn: true,
        'p-0': loading,
        inlineFormClass: inlineFormClass,
        mimicLabelClass: mimicLabelClass,
      },
    ]"
    :style="(rounded ? 'border-radius: 50%; ' : ' ') + btnStyle"
    @mousedown="addRipple"
    @click="onClick"
  >
    <TransitionGroup class="ripples" name="grow" tag="div">
      <div
        v-for="rip in ripples"
        :key="rip.id"
        class="ripple"
        :class="rippleColorType"
        :style="{
          top: rip.top,
          left: rip.left,
          width: rip.width,
          height: rip.height,
        }"
      ></div>
    </TransitionGroup>
    <div :class="{ 'btn-content-wrapper': true, [btnContentClass]: btnContentClass }">
      <slot v-if="!loading" />
      <LeafBeat v-if="loading" classes="mx-auto" :size="loadingSize" />
    </div>
    <BTooltip
      v-if="tooltipMessage"
      :target="buttonId ? buttonId : uniqueId"
      :placement="tooltipPlacement"
      triggers="hover"
      :delay="{ show: 800, hide: 100 }"
    >
      {{ tooltipMessage }}
    </BTooltip>
  </button>
</template>

<script>
import LeafBeat from "@/components/common/loaders/LeafBeat.vue";
import { navTo } from "@/services/helpers";

export default {
  components: { LeafBeat },
  props: {
    disabled: { type: Boolean, default: false },
    variant: { type: String, default: "primary" },
    outline: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    loadingSize: { type: String, default: "la-2x" },
    externalLink: { type: String },
    internalLink: { type: String },
    flat: { type: Boolean, default: false },
    ghosted: { type: Boolean, default: false },
    ripple: { type: Boolean, default: true },
    inlineFormClass: { type: Boolean, default: false },
    mimicLabelClass: { type: Boolean, default: false },
    rippleColor: { type: String },
    btnContentClass: { type: String },
    btnContainerClass: { type: String },
    tooltipMessage: { type: String },
    buttonId: { type: String },
    tooltipPlacement: { type: String, default: "bottom" },
    btnStyle: { type: String, default: "" },
    pill: { type: Boolean, default: false },
  },
  emits: ["click"],
  data() {
    return {
      ripples: [],
      tooltipVisible: false,
      uniqueId: Math.random().toString(36).substring(7),
    };
  },
  computed: {
    rippleColorType() {
      if (this.rippleColor) {
        return `ripple-color-${this.rippleColor}`;
      }
      return `ripple-color-${this.variant}`;
    },
    btnType() {
      let returnVaraint = null;
      returnVaraint = `btn-${this.variant}`;
      if (this.flat) {
        returnVaraint = `btn-flat-${this.variant}`;
      }
      if (this.outline) {
        returnVaraint = `btn-outline-${this.variant}`;
      }
      if (this.ghosted) {
        returnVaraint = `btn-ghosted-${this.variant}`;
      }

      if (this.pill) {
        returnVaraint += " btn-pill";
      }
      return returnVaraint;
    },
  },
  mounted() {
    const width = this.$refs.container.offsetWidth;
    const height = this.$refs.container.offsetHeight;
    this.rippleWidth = width > height ? width : height;
    this.halfRippleWidth = this.rippleWidth / 2;
    window.addEventListener("mouseup", this.purgeRipples);
  },
  beforeUnmount() {
    window.removeEventListener("mouseup", this.purgeRipples);
  },
  methods: {
    navTo,
    onClick(e) {
      this.tooltipVisible = false;
      if (!this.disabled && !this.loading) {
        this.handleClick(e);
      }
    },
    addRipple(e) {
      if (!this.disabled && !this.loading) {
        if (!this.rippleWidth || this.rippleWidth == undefined) {
          const width = this.$refs.container.offsetWidth; // button width
          const height = this.$refs.container.offsetHeight; // button height
          this.rippleWidth = width > height ? width : height; // riddle width is the larger of the two
          this.halfRippleWidth = this.rippleWidth / 2;
        }

        const { left, top } = this.$refs.container.getBoundingClientRect();

        const clickedX = e.clientX - left; // x position of click relative to the button
        const clickedY = e.clientY - top; // y position of click relative to the button

        const rippleId = Date.now();
        this.ripples.push({
          width: `${this.rippleWidth}px`,
          height: `${this.rippleWidth}px`,
          left: `${clickedX - this.halfRippleWidth}px`,
          top: `${clickedY - this.halfRippleWidth}px`,
          id: rippleId,
        });
      }
    },
    purgeRipples() {
      this.ripples = [];
    },
    handleClick(e) {
      if (this.externalLink) {
        window.open(this.externalLink);
      } else if (this.internalLink) {
        this.navTo(this.internalLink);
      } else {
        this.$emit("click", e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
// .btn-outline-primary:hover {
//   background: transparent;
//   color: $primary;
// }
//
// .btn-outline-primary:active {
//   background: transparent !important;
//   color: darken( $primary, 10% );
// }

.ripple-outer {
  position: relative;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
}
.ripples {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
.ripple {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
}
.grow-enter-active,
.grow-enter-to-active {
  transition: all 800ms ease-out;
}
.grow-leave-active,
.grow-leave-to-active {
  transition: all 700ms ease-out;
}
.grow-enter-from {
  transform: scale(0);
  opacity: 1;
}
.grow-enter-to {
  transform: scale(4);
  opacity: 1;
}
.grow-leave-from {
  transform: scale(4);
  opacity: 1;
}
.grow-leave-to {
  transform: scale(4);
  opacity: 0;
}

.btn-content-wrapper {
  position: relative;
  top: 1px;
}

.btn-pill {
  border-radius: 50px;
}

.inlineFormClass {
  height: 38px;
  margin-bottom: 1px;
  position: relative;
  min-width: 100px;
}

.mimicLabelClass {
  margin-top: 24px;
}
</style>
