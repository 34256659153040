<template>
  <input
    :id="id"
    :type="type"
    :class="formInputClasses"
    :placeholder="placeholder"
    :value="modelValue ? modelValue : value"
    :autocomplete="autocomplete"
    :autoFocus="autofocus"
    :disabled="disabled"
    :name="name"
    :required="required"
    :readonly="readonly"
    :min="min"
    :max="max"
    :step="step"
    :pattern="pattern"
    :size="size"
    :nowheel="nowheel"
    :form="form"
    :list="list"
    :inputmode="inputmode"
    @input="onInput"
    @keyup.enter="onEnter"
    @keyup="(event) => $emit('keyup', event)"
    @keydown="(event) => $emit('keydown', event)"
    @blur="(event) => $emit('blur', event)"
  />
</template>
<script>
export default {
  name: "BFormInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
    step: {
      type: [String, Number],
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    nowheel: {
      type: Boolean,
      default: false,
    },
    form: {
      type: String,
      default: null,
    },
    list: {
      type: String,
      default: null,
    },
    inputmode: {
      type: String,
      default: null,
    },
    controlSize: {
      type: String,
      default: "md",
    },
  },
  emits: ["input", "change", "enter", "keyup", "keydown", "keypress", "update:modelValue", "keyup.enter", "enter", "blur"],
  computed: {
    formInputClasses() {
      return {
        "form-control": true,
        "form-control-lg": this.controlSize === "lg",
        "form-control-sm": this.controlSize === "sm",
        "is-invalid": this.state === false,
        "is-valid": this.state === true,
      };
    },
  },
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.$emit("input", e.target.value);
    },
    onEnter(e) {
      this.$emit("enter", e.target.value);
      this.$emit("keyup.enter", e.target.value);
    },
  },
};
</script>
